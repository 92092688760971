import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
// import { Container, Row, Col } from 'reactstrap'

import Layout from '../components/layout'
import { rhythm } from '../utils/typography'

// NOTE: Cams do *not* link to original image since highest resolution image is already used. Although, it would be possible to open the image zoomed (200%? or 90vw?) within a modal.

const groups = {
  US26: [
    {
      title: 'US26 at Keyes Summit',
      imgSrc: 'https://tripcheck.com/roadcams/cams/US26%20at%20Keyes%20Summit_pid2550.jpg',
    },
    {
      title: 'US26 at Ochoco Summit (Looking East)',
      imgSrc: 'https://tripcheck.com/roadcams/cams/US26%20at%20Ochoco%20Summit_pid2588.jpg',
    },
    {
      title: 'US26 Near Warm Springs (Looking East)',
      imgSrc: 'https://tripcheck.com/roadcams/cams/US26%20Near%20Warm%20Springs_pid1742.jpg',
    },
  ],
  US20: [
    {
      title: 'US20 at Santiam Pass (Looking East)',
      imgSrc: 'https://tripcheck.com/roadcams/cams/Santiam%20Pass_pid2728.jpg',
    },
    {
      title: 'US20 at Santiam Jct (Looking East)',
      imgSrc: 'https://tripcheck.com/roadcams/cams/US20%20at%20Santiam%20Jct_pid1675.jpg',
    },
    {
      title: 'US20 at Santiam Jct (Looking West)',
      imgSrc: 'https://tripcheck.com/roadcams/cams/US20%20at%20Santiam%20Jct%20WB_pid3342.jpg',
    },
    {
      title: 'US20 at Bend - Butler Market Rd (Looking North)',
      imgSrc: 'https://tripcheck.com/roadcams/cams/US20%20at%20Butler%20Market_pid3338.jpg',
    },
    {
      title: 'US20 at Sisters (Looking West)',
      imgSrc: 'https://tripcheck.com/roadcams/cams/Sisters_pid653.jpg',
    },
    {
      title: 'US20 at Hampton',
      imgSrc: 'https://tripcheck.com/roadcams/cams/US20%20at%20Hampton_pid2565.jpg',
    },
    {
      title: 'US20 at Horse Ridge (Looking West)',
      imgSrc: 'https://tripcheck.com/roadcams/cams/US20%20at%20Horse%20Ridge_pid2418.jpg',
    },
    {
      title: 'US20 at US395 - Riley (Looking West)',
      imgSrc: 'https://tripcheck.com/roadcams/cams/US20%20at%20US395%20Riley_pid2569.jpg',
    },
    {
      title: 'US20 at US395 - Burns (Looking East)',
      imgSrc: 'https://tripcheck.com/roadcams/cams/US20%20at%20US395%20Burns%20EB_pid3991.jpg',
    },
  ],
  US97: [
    {
      title: 'US97 at Cooley Rd.',
      imgSrc: 'https://www.tripcheck.com/roadcams/cams/US97%20at%20Cooley%20Rd_pid3830.jpg',
    },
    {
      title: 'US97 at Robal Rd',
      imgSrc: 'https://tripcheck.com/roadcams/cams/US97%20at%20Robal%20Rd_pid4039.jpg',
    },
    {
      title: 'US97 at Empire Blvd',
      imgSrc: 'https://www.tripcheck.com/roadcams/cams/US97%20at%20Empire_pid3161.jpg',
    },
    {
      title: 'US20 at Bend - US97 and North Division',
      imgSrc: 'https://tripcheck.com/roadcams/cams/NorthDivision_pid604.jpg',
    },
    {
      title: 'US97 Bend - Ramp to Wall and Revere',
      imgSrc: 'https://www.tripcheck.com/roadcams/cams/US97%20at%20Wall%20and%20Revere_pid3334.jpg',
    },
    {
      title: 'US97 at Colorado',
      imgSrc: 'https://www.tripcheck.com/roadcams/cams/US97%20at%20Colorado_pid3157.jpg',
    },
    {
      title: 'Redmond at Roberts Field',
      imgSrc: 'https://tripcheck.com/roadcams/cams/RedmondAirport_pid649.jpg',
    },
    {
      title: 'US97 Near ORE138 Jct - Milepoint 214',
      imgSrc: 'https://tripcheck.com/roadcams/cams/US97%20at%20MP214%20Near%20ORE138%20Jct_pid1729.jpg',
    },
    {
      title: 'US97 at Chemult',
      imgSrc: 'https://tripcheck.com/roadcams/cams/Chemult_pid621.jpg',
    },
    {
      title: 'US97 at Crescent',
      imgSrc: 'https://tripcheck.com/roadcams/cams/US97%20at%20Crescent%20North_pid2119.jpg',
    },
    {
      title: 'US97 at Evergreen / ORE126',
      imgSrc: 'https://www.tripcheck.com/roadcams/cams/US97%20at%20Evergreen_pid3866.jpg',
    },
    {
      title: 'US97 at Juniper Butte',
      imgSrc: 'https://tripcheck.com/roadcams/cams/Juniper%20Butte_pid2562.jpg',
    },
    {
      title: 'US97 at Lava Butte (Looking South)',
      imgSrc: 'https://tripcheck.com/roadcams/cams/Lava%20Butte%20S_pid1923.jpg',
    },
    {
      title: 'US97 at Lava Butte (Looking North)',
      imgSrc: 'https://tripcheck.com/roadcams/cams/LavaButte_pid631.jpg',
    },
    {
      title: 'US97 at LaPine',
      imgSrc: 'https://tripcheck.com/roadcams/cams/LaPine_pid630.jpg',
    },
    {
      title: 'US97 at Ogden Wayside (Looking North)',
      imgSrc: 'https://tripcheck.com/roadcams/cams/US97%20at%20Ogden%20Wayside%20N_pid2643.jpg',
    },
    {
      title: 'US97 at Ogden Wayside (Looking South)',
      imgSrc: 'https://tripcheck.com/roadcams/cams/US97%20at%20Ogden%20Wayside%20S_pid2647.jpg',
    },
    // BELOW CAM WAS UNAVAILABLE WHEN LAST CHECKED BUT STILL LISTED ON TRIPCHECK CAMS PAGE
    // {
    //   title: 'US97 at Redmond - Highland Ave',
    //   imgSrc: 'https://tripcheck.com/roadcams/cams/Redmond%20Highland_pid1893.jpg',
    // },
    {
      title: 'US97 at Rocking Horse',
      imgSrc: 'https://tripcheck.com/roadcams/cams/US97%20at%20Rocking%20Horse_pid3101.jpg',
    },
    {
      title: 'US97 at SW 61st St S',
      imgSrc: 'https://tripcheck.com/roadcams/cams/US97%20at%20SW%2061st%20St%20S_pid4073.jpg',
    },
    // BELOW CAM WAS UNAVAILABLE WHEN LAST CHECKED BUT STILL LISTED ON TRIPCHECK CAMS PAGE
    // {
    //   title: 'US97 at Trout Creek',
    //   imgSrc: 'https://tripcheck.com/roadcams/cams/Trailer%20F_pid4007.jpg',
    // },
    {
      title: 'US97 at US26 Jct',
      imgSrc: 'https://tripcheck.com/roadcams/cams/US97%20at%20US26%20Jct%20NB_pid3511.jpg',
    },
  ],
  'CITY (Bend)': [
    {
      title: 'Century Drive at MP 21.11',
      imgSrc: 'https://tripcheck.com/roadcams/cams/Century%20Dr%20at%20MP%2021.11%20E_pid3545.jpg',
    },
    {
      title: 'Century Drive at MP 21.11 (Looking West)',
      imgSrc: 'https://tripcheck.com/roadcams/cams/Century%20Dr%20at%20MP%2021.11%20W_pid3557.jpg',
    },
  ],
  ORE58: [
    {
      title: 'ORE58 at Willamette Pass (Looking East)',
      imgSrc: 'https://tripcheck.com/roadcams/cams/WillamettePass_pid658.jpg',
    },
  ],
  ORE370: [
    {
      title: 'ORE370 at Lone Pine Rd (Looking West)',
      imgSrc: 'https://tripcheck.com/roadcams/cams/ORE370%20at%20Lone%20Pine%20Rd_pid2369.jpg',
    },
    {
      title: 'ORE370 at Lone Pine Rd (Looking North)',
      imgSrc: 'https://tripcheck.com/roadcams/cams/ORE370%20at%20Lone%20Pine%20Rd._pid2373.jpg',
    },
  ],
  ORE380: [
    {
      title: 'ORE380 at Paulina (Looking East)',
      imgSrc: 'https://tripcheck.com/roadcams/cams/Paulina_pid1362.jpg',
    },
  ],
  US395B: [
    {
      title: 'US395B at Long Creek (Looking South)',
      imgSrc: 'https://tripcheck.com/roadcams/cams/US395B%20at%20Long%20Creek_pid3495.jpg',
    },
  ],
  US395C: [
    {
      title: 'US395C at Canyon City (Looking South)',
      imgSrc: 'https://tripcheck.com/roadcams/cams/US395C%20at%20Canyon%20City_pid3515.jpg',
    },
  ],
};

const styles = {
  groups: {
    paddingTop: '4rem',
    paddingBottom: '4rem',
  },
}

class BlogIndex extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const title = `Traffic | ${siteTitle}`

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet title={siteTitle} />
        <div className="groups" style={styles.groups}>
          {Object.keys(groups).map(group =>
            <section
              key={group}
              style={{ marginBottom: 80 }}
            >
              <h3 style={{ textAlign: 'center' }}>
                {group}
              </h3>
              <ul
                className="cams"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  listStyle: 'none',
                  marginLeft: 0,
                  paddingLeft: 0,
                }}
              >
                {groups[group].map((cam, i) =>
                  <li
                    key={i}
                    className="cam"
                    style={{
                      backgroundColor: '#EFEFEF',
                      borderRadius: '2px',
                      // flex: 1,
                      // margin: rhythm(1/2),
                      padding: rhythm(1/2),
                      // maxHeight: 314,
                      // maxWidth: 300,
                      // width: '100%',
                    }}
                  >
                    <img
                      src={cam.imgSrc}
                      alt={cam.title}
                      width="100%"
                      height="auto"
                    />
                  </li>
                )}
              </ul>
            </section>
          )}
        </div>
      </Layout>
    )
  }
}

BlogIndex.propTypes = {
  route: PropTypes.object,
}

export default BlogIndex

export const pageQuery = graphql`
  query TrafficQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
